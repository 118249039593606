import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue'
import NotFound from '../pages/404.vue'
import { tools } from "@/data/tools.js";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomePage,
        },
        { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
        ...tools,

    ],
});

export default router;