import HomePage from '../pages/HomePage.vue'
import HashText from '../pages/HashText.vue'
import DeviceInformation from '../pages/DeviceInfo.vue'
import ToArray from '../pages/ToArray.vue'
import CountryInfo from '../pages/CountryInfo.vue'
import ColorGenerator from '../pages/ColorGenerator.vue'
import CSV from '../pages/CsvToJson.vue'
import CSVTable from '../pages/CsvTable.vue'
import JSON from '../pages/JsonToCsv.vue'
import Base64 from '../pages/Base64Encode.vue'
import NumberConverter from '../pages/NumberConverter.vue'
import PasswordGenerator from '../pages/PasswordGenerator.vue'
import PolylineEncoder from '../pages/PolylineEncoder.vue'
import UuidGenerator from '../pages/UUIDGenerator.vue'
import UnitConverter from '../pages/UnitConverter.vue'
import UrlEncoder from '../pages/UrlEncoder.vue'
import HTMLEscape from '../pages/HTMLEscape.vue'
import Exif from '../pages/EXIF.vue'
import Epoch from '../pages/Epoch.vue'

export const tools = [
    {
        id: "allTools",
        link: "/all",
        path: "/all",
        name: "All Tools",
        short_description: "All available tools",
        tooltip: "Dashboard",
        icon: "bx-grid-alt",
        component: HomePage,
    },
    {
        id: "arrayThis",
        link: "/array",
        path: "/array",
        name: "Array This",
        short_description: "Convert list to Array",
        tooltip: "Array This",
        icon: "bx-code-block",
        component: ToArray,
    },
    {
        id: "base64Encode",
        link: "/base64",
        path: "/base64",
        name: "Base64 Encode",
        short_description: "Text to Base64",
        tooltip: "Base64 Encode",
        icon: "bx-pyramid",
        component: Base64,
    },
    {
        id: "countryInfo",
        link: "/country",
        path: "/country",
        name: "Country Info",
        short_description: "Country codes for all countries",
        tooltip: "CountryInfo",
        icon: "bx-globe",
        component: CountryInfo,
    },
    {
        id: "colorGenerator",
        link: "/color",
        path: "/color",
        name: "Color Generator",
        short_description: "Generate colors",
        tooltip: "Color Generator",
        icon: "bx-palette",
        component: ColorGenerator,
    },
    {
        id: "csvToJson",
        link: "/csvtojson",
        path: "/csvtojson",
        name: "CSV to JSON",
        short_description: "Convert CSV to JSON",
        tooltip: "CSV to JSON",
        icon: "bxs-file-json",
        component: CSV,
    },
    {
        id: "csvTable",
        link: "/csvtable",
        path: "/csvtable",
        name: "CSV Table",
        short_description: "Create table from CSV",
        tooltip: "CSV Table",
        icon: "bxs-file-json",
        component: CSVTable,
    },
    {
        id: "deviceInformation",
        link: "/device-info",
        path: "/device-info",
        name: "Device Information",
        short_description: "Get information from current device",
        tooltip: "Device Information",
        icon: "bx-devices",
        component: DeviceInformation,
    },
    {
        id: "exifInformation",
        link: "/exif",
        path: "/exif",
        name: "EXIF Information",
        short_description: "Get metadata from an Image",
        tooltip: "EXIF Information",
        icon: "bx-image-alt",
        component: Exif,
    },
    {
        id: "epochConverter",
        link: "/epoch",
        path: "/epoch",
        name: "Epoch Converter",
        short_description: "Get date from epoch",
        tooltip: "Epoch Converter",
        icon: "bx-timer",
        component: Epoch,
    },
    {
        id: "hashText",
        link: "/hash-text",
        path: "/hash-text",
        name: "Hash Text",
        short_description: "Hash text using different algos",
        tooltip: "Hash Text",
        icon: "bx-text",
        component: HashText,
    },
    {
        id: "htmlEscape",
        link: "/html-escape",
        path: "/html-escape",
        name: "HTML Escape",
        short_description: "Escape HTML Characters",
        tooltip: "HTML Escape",
        icon: "bxs-file-html",
        component: HTMLEscape,
    },
    {
        id: "jsonToCsv",
        link: "/jsontocsv",
        path: "/jsontocsv",
        name: "JSON to CSV",
        short_description: "Convert JSON to CSV",
        tooltip: "JSON to CSV",
        icon: "bxs-file-json",
        component: JSON,
    },
    {
        id: "numberConverter",
        link: "/number-converter",
        path: "/number-converter",
        name: "Number Converter",
        short_description: "Convert number to other number bases",
        tooltip: "Number Converter",
        icon: "bx-calculator",
        component: NumberConverter,
    },
    {
        id: "passwordGenerator",
        link: "/password",
        path: "/password",
        name: "Password Generator",
        short_description: "Generate a secure password",
        tooltip: "Password Generator",
        icon: "bx-lock",
        component: PasswordGenerator,
    },
    {
        id: "polylineEncoder",
        link: "/polyline",
        path: "/polyline",
        name: "Polyline Encoder",
        short_description: "Encode / Decode polylines",
        tooltip: "Polyline Encoder",
        icon: "bx-map-alt",
        component: PolylineEncoder,
    },
    {
        id: "unitConverter",
        link: "/unit-converter",
        path: "/unit-converter",
        name: "Unit Converter",
        short_description: "Convert from unit to unit",
        tooltip: "UUID Generator",
        icon: "bx-drink",
        component: UnitConverter,
    },
    {
        id: "urlEncoder",
        link: "/url-encoder",
        path: "/url-encoder",
        name: "URL Encoder",
        short_description: "URL Encode/Decode",
        tooltip: "URL Encoder",
        icon: "bx-band-aid",
        component: UrlEncoder,
    },
    {
        id: "uuidGenerator",
        link: "/uuid",
        path: "/uuid",
        name: "UUID Generator",
        short_description: "Generate an UUID",
        tooltip: "UUID Generator",
        icon: "bx-fingerprint",
        component: UuidGenerator,
    },
];