var tinycolor = require("tinycolor2");

export const colorUtil = {
    methods: {
        getColorOld(color, percentage, index, action) {
            let p = index * percentage;
            let colorCorrected = "";

            switch (action) {
                case "ligthen":
                    colorCorrected = tinycolor(color).brighten(p).toString();
                    break;
                case "darken":
                    colorCorrected = tinycolor(color).darken(p).toString();
                    break;
                case "saturate":
                    colorCorrected = tinycolor(color).saturate(p).toString();
                    break;
                case "desaturate":
                    colorCorrected = tinycolor(color).desaturate(p).toString();
                    break;
                case "monochromatic":
                    colorCorrected = tinycolor(color).monochromatic(p).toString();
                    break;
                case "brighten":
                    colorCorrected = tinycolor(color).brighten(p).toString();
                    break;
            }

            return {
                backgroundColor: colorCorrected.toString(),
            };
        },
        getColor(color, percentage, index, action, colorType) {
            let p = index * percentage;
            let colorCorrected = "";

            switch (action) {
                case "ligthen":
                    colorCorrected = tinycolor(color).brighten(p);
                    break;
                case "darken":
                    colorCorrected = tinycolor(color).darken(p);
                    break;
                case "saturate":
                    colorCorrected = tinycolor(color).saturate(p);
                    break;
                case "desaturate":
                    colorCorrected = tinycolor(color).desaturate(p);
                    break;
                case "monochromatic":
                    colorCorrected = tinycolor(color).monochromatic(p);
                    break;
                default:
                    colorCorrected = tinycolor(color).brighten(p);
                    break;
            }
            if (colorType == "hex") {
                return colorCorrected.toHexString();
            } else if (colorType == "rgb") {
                return colorCorrected.toRgbString();
            } else if (colorType == "name") {
                return colorCorrected.toName();
            } else {
                return colorCorrected.toString();
            }
        },
        generateColorArray(color, type) {
            let colors = "";

            switch (type) {
                case "triad":
                    colors = tinycolor(color).triad();
                    colors = colors.map((clr) => {
                        return clr.toHexString();
                    });

                    break;
                case "tetrad":
                    colors = tinycolor(color).tetrad();
                    colors = colors.map((clr) => {
                        return clr.toHexString();
                    });

                    break;
                case "split":
                    colors = tinycolor(this.color).splitcomplement();
                    colors = colors.map((clr) => {
                        return clr.toHexString();
                    });

                    break;
                case "monochromatic":
                    colors = tinycolor(this.color).monochromatic();
                    colors = colors.map((clr) => {
                        return clr.toHexString();
                    });

                    break;
                case "analogous":
                    colors = tinycolor(this.color).analogous();
                    colors = colors.map((clr) => {
                        return clr.toHexString();
                    });

                    break;
            }
            return colors;
        },
        generateRandomColor() {
            return tinycolor.random().toHexString()
        },
        getColorSimple(color) {
            return tinycolor(color);
        }
    }
}