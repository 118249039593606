<template>
  <div class="theme-switch-wrapper">
    <label class="theme-switch" :for="id">
      <input
        @change="$emit('changeValue', this.state)"
        type="checkbox"
        v-model="state"
        :value="state"
        :id="id"
      />
      <div class="slider round"></div>
    </label>
    <div class="text">{{ text }}</div>
  </div>
</template>


<script>
export default {
  name: "InputCheckbox",
  created() {},
  props: {
    text: String,
    id: String,
    initialState: Boolean,
  },
  data() {
    return {
      state: this.initialState,
    };
  },
  computed: {},
};
</script>


<style scoped>
.theme-switch-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
}

.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.theme-switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.text {
  height: 100%;
  margin-right: 5px;
}
</style>