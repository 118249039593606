<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />

    <div>
      <div class="code-container">
        <div class="left">
          <div class="header-row">
            <div>CSV to convert</div>
            <file-upload @result="(result) => (csv = result)"></file-upload>
          </div>

          <textarea v-model="csv" class="array"></textarea>
          <button class="btn-green" @click="convertToJson(csv)">Convert</button>
        </div>
        <div class="right">
          <div class="header-row">
            <div>Output</div>
            <div class="checkbox-row">
              <input-checkbox
                :id="'parseNumbers'"
                :initial-state="parseNumbers"
                :text="'Parse numbers'"
                @change-value="(n) => (parseNumbers = n)"
              ></input-checkbox>
              <input-checkbox
                :id="'minify'"
                :initial-state="minify"
                :text="'Minify'"
                @change-value="(n) => (minify = n)"
              ></input-checkbox>
            </div>
          </div>

          <textarea v-model="json" class="array"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import InputCheckbox from "@/components/Checkbox";
import FileUpload from "@/components/FileUpload";
import ToolHeader from "@/components/ToolHeader";

import { tools } from "@/data/tools.js";

const csv2json = require("../utils/csv2json.js");
const json_beautifier = require("../utils/json_beautifier.js");

export default {
  name: "CsvToJson",
  components: {
    InputCheckbox,
    FileUpload,
    ToolHeader
  },
  mounted() {
    document.title = "Tools - CSV to JSON";
  },
  created() {},
  data() {
    return {
      parseNumbers: true,
      minify: false,
      csv: `Year,Make,Model,Description,Price
1997,Ford,E350,ac abs moon,3000.00
1999,Chevy,Venture Extended Edition,"",4900.00
1999,Chevy,Venture Extended Edition, Very Large ,5000.00
1996,Jeep,Grand Cherokee,MUST SELL!,4799.00`,
      json: "",
      curentComponent: tools.find((tool) => tool.id === "csvToJson"),

    };
  },
  methods: {
    convertToJson(csv) {
      let json = csv2json(csv, { parseNumbers: this.parseNumbers });
      this.json = json_beautifier(json, {
        dropQuotesOnNumbers: this.parseNumbers,
        inlineShortArrays: false,
        minify: this.minify,
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
.header-row {
  min-height: 80px;
}

.checkbox-row {
  display: flex;
}
</style>