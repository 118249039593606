<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />

    <div class="btn-row">
      <div class="btn-green" @click="downloadJson">Download as JSON</div>
      <div class="btn-red" @click="downloadCSV">Download as CSV</div>
    </div>
    <div class="tbl-container">
      <data-table
        :columns="columns"
        :rows="countries"
        :line-numbers="true"
        theme="polar-bear"
      ></data-table>
    </div>
  </div>
</template>


<script>
import Table from "@/components/Table";
import { countries } from "@/data/countries.js";
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";

export default {
  name: "CountryInfo",
  components: {
    DataTable: Table,
    ToolHeader
  },
  created() {},
  mounted() {
    document.title = "Tools - Country Info";
  },
  data() {
    return {
      curentComponent: tools.find((tool) => tool.id === "countryInfo"),
      countries: countries,
      columns: [
        {
          label: "Country",
          field: "country",
        },
        {
          label: "Alpha 2",
          field: "alpha2",
        },
        {
          label: "Alpha 3",
          field: "alpha3",
        },
        {
          label: "Numeric",
          field: "numericCode",
          type: "number",
        },
        {
          label: "Latitude (Average)",
          field: "avgLatitude",
          type: "number",
        },
        {
          label: "Longitude (Average)",
          field: "avgLongitude",
          type: "number",
        },
      ],
    };
  },
  computed: {},
  methods: {
    downloadJson() {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.countries));
      var dlAnchorElem = document.createElement("a");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "scene.json");
      dlAnchorElem.click();
    },
    downloadCSV() {
      const items = countries;
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = Object.keys(items[0]);
      const csv = [
        header.join(","), // header row first
        ...items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");

      var dataStr = "data:application/csv;charset=UTF-8," + csv;
      var dlAnchorElem = document.createElement("a");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "scene.csv");
      dlAnchorElem.click();
    },
  },
};
</script>

<style scoped>
.tbl-container {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}

.btn-row {
  display: flex;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
</style>