<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />

    <div><i>Type a color in hex, rgb, name</i></div>
    <div class="input-box">
      <div class="swatch-info-header">
        <span class="color-text">
          {{ getColor(color, 0, 0, "default", "hex") }} <br />
          {{ getColor(color, 0, 0, "default", "rgb") }}</span><br />
      </div>
      <div class="header-color" :style="currentColor"></div>
      <div>
        <input class="mr-15 form-control" v-model="color" />
        <span class="color-type"> / </span>
        <input class="ml-15 mr-15 form-control" v-model="percentage" />
        <span class="color-type"> % </span>
      </div>
    </div>
    <div>Press <span class="hightlight">[space]</span> for a new color</div>

    <div class="container">
      <div class="color-box" v-for="type in colorTypes" :key="type">
        <div>
          <span class="color-type-text">{{ type }}</span>
        </div>
        <div class="colors">
          <div :key="n + type" v-for="n in 100 / percentage" @click="copyTestingCode(type, percentage, n)">
            <div :id="type + '_' + n" class="individual-color" :style="'background-color: ' + getColor(color, percentage, n, type)
      "></div>
            <div class="swatch-info">
              <span class="color-text">{{
      getColor(color, percentage, n, type, "hex")
    }}</span><br />
            </div>
          </div>
        </div>
      </div>

      <div class="color-box" v-for="type in readableTypes" :key="type">
        <div>
          <span class="color-type-text">{{ type }}</span>
        </div>
        <div class="colors">
          <div :key="color + type + index" v-for="(color, index) in generateColorArray(color, type)">
            <div class="individual-color" :style="hexToClass(color)"></div>
            <div class="swatch-info">
              <span class="color-text">{{ color }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { colorUtil } from "@/utils/colorUtil";
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";

export default {
  mixins: [colorUtil],
  name: "color-generator",
  components: {
    ToolHeader
  },
  data: function () {
    return {
      colorTypes: ["ligthen", "darken", "saturate", "desaturate"],
      readableTypes: ["triad", "tetrad", "monochromatic", "analogous", "split"],
      percentage: 10,
      color: this.generateRandomColor(), //#eb2848,#e2fd71
      selectedColor: {
        string: "",
        rgb: "",
        hsl: "",
      },
      curentComponent: tools.find((tool) => tool.id === "colorGenerator"),

    };
  },
  mounted() {
    window.addEventListener("keypress", (e) => {
      let code = e.code;

      if (code === "Space") {
        // prevent default behaviour
        e.preventDefault();
        this.color = this.generateRandomColor();
      }
    });
  },
  computed: {
    currentColor() {
      let color = this.color;
      let colorCorrected = this.getColorSimple(color);
      return {
        backgroundColor: colorCorrected.toHexString(),
      };
    },
  },
  methods: {
    hexToClass(color) {
      return {
        backgroundColor: color,
      };
    },
  },
};
</script>


<style scoped>
.container {
  margin-right: 30px;
  margin-left: 30px;
}

.form-control {
  border: 4px solid #ddd;
  width: 260px;
  display: inline;
  height: 55px;
  text-align: center;
  font-size: 140%;
}

.colors {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 20px;
}

.individual-color {
  width: 100px;
  height: 60px;
  margin: 5px 5px 0px 5px;
  border-radius: 5px 5px 0px 0px;
}

.header-color {
  width: 65px;
  height: 65px;
  margin-right: 5px;
  /* border-radius: 5px 5px 5px 5px; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.color-type {
  font-size: 20px;
  color: var(--heading-color);
  text-transform: capitalize;
}

.color-type-text {
  font-size: 30px;
  color: var(--font-color);

  text-transform: capitalize;
}

.color-text {
  font-family: "Courier New", Courier, monospace;
  /* font-family: "Monaco", Monaco, monospace; */
  font-size: 1rem;
  padding-bottom: 0.3em;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}

.heading {
  color: var(--heading-color);
  margin: 30px 0 0;
  padding: 0;
  font-family: "Courier New", Courier, monospace;
  font-size: 280%;
  text-align: center;
}

.input-box {
  padding: 30px;
  /* background-color: #4f5861; */
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.swatch-info {
  padding: 0.2em 0px 0.3px 0px;
  color: #808080;
  background: #3c3b3d;
  max-height: 30px;
  margin-right: 5px;
  margin-left: 5px;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
}

.swatch-info-header {
  /* padding: 0.2em 0px 0.3px 0px; */
  color: #808080;
  background: #3c3b3d;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  min-height: 65px;
  max-height: 60px;
}

.hightlight {
  font-family: "Courier New", Courier, monospace;
  color: #fc0df6;
}
</style>