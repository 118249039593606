<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />

    <div class="mt-30">
      <div class="lp-pg-generated-password">
        <form id="GENERATED-PASSWORD-FORM">
          <input :value="generatedPassword" id="GENERATED-PASSWORD" class="lp-pg-generated-password__input"
            type="text" />
        </form>
        <div class="lp-pg-generated-password__icon-wrapper">
          <span class="copy-button" v-clipboard:copy="generatedPassword" v-clipboard:success="onCopy"
            v-clipboard:error="onError">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-copy" width="36" height="36"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="8" y="8" width="12" height="12" rx="2" />
              <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
            </svg>
          </span>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-refresh" width="36" height="36"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
              <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
            </svg></span>
        </div>
      </div>
      <div class="wrapper">
        <label>Length</label>
        <input type="number" v-model="length" class="lp-custom-range__number" />
        <input-slider :id="'numbers'" :initial-state="useNumbers" :text="'Use numbers'"
          @change-value="(n) => (useNumbers = n)"></input-slider>
        <input-slider :id="'symbols'" :initial-state="useSymbols" :text="'Use symbols'"
          @change-value="(n) => (useSymbols = n)"></input-slider>
        <input-slider :id="'upperCase'" :initial-state="useUppercase" :text="'Use uppercase'"
          @change-value="(n) => (useUppercase = n)"></input-slider>
        <input-slider :id="'lowerCase'" :initial-state="useLowercase" @change-value="(n) => (useLowercase = n)"
          :text="'Use lowercase'"></input-slider>
      </div>
    </div>
  </div>
</template>


<script>
import InputSlider from "@/components/Slider";
import { createPassword } from "@/utils/passwordGenerator";
import { useToast } from "vue-toastification";
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";

export default {
  name: "PasswordGenerator",
  components: {
    InputSlider,
    ToolHeader

  },
  created() {
    this.password = createPassword(true, true, true, true, this.length);
  },
  mounted() {
    document.title = "Tools - Password Generator";
  },
  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      length: 20,
      useNumbers: true,
      useSymbols: true,
      useUppercase: true,
      useLowercase: true,
      password: "9rT6@V^1Z76eXtPzvwo3",
      curentComponent: tools.find((tool) => tool.id === "passwordGenerator"),

    };
  },
  methods: {
    refresh() {
      createPassword(
        this.useUppercase,
        this.useLowercase,
        this.useNumbers,
        this.useSymbols,
        this.length
      );
    },
    onCopy: function () {
      this.toast.success("Copied", {
        timeout: 2000,
      });
    },
    onError: function (e) {
      this.toast.error("Failed to copy: " + e, {
        timeout: 2000,
      });
    },
  },
  computed: {
    generatedPassword() {
      let pw = createPassword(
        this.useUppercase,
        this.useLowercase,
        this.useNumbers,
        this.useSymbols,
        this.length
      );

      return pw;
    },
    strength() {
      return "strong";
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}

.icon-tabler:hover {
  transform: scale(1.1);
}

.lp-pg-generated-password__input {
  width: 650px;
}
</style>