
<template>
  <div class="card shadow h-100 py-2" :style="cardStyle">
    <div class="card-body">
      <div class="row no-gutters align-items-center">
        <div title="" class="col mr-2">
          <div
            class="text-xs font-weight-bold text-uppercase mb-1"
            :style="textStyle"
          >
            {{ this.title }}
          </div>
          <div class="h5 mb-0 font-weight-bold text-gray-100">
            {{ this.sub_title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "GradientCard",
  props: {
    title: {
      type: String,
    },
    sub_title: {
      type: String,
    },
    gradient_type: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  computed: {
    textStyle() {
      return {
        color: `${this.color} !important`,
        textDecoration: "underline",
        fontSize:"20px",
        textUnderlinePosition: "under",
        textDecorationStyle: "dotted",
      };
    },
    cardStyle() {
      if (this.gradient_type == "ocean0") {
        return {
          backgroundImage: `linear-gradient(135deg, #EE9AE5 10%, #5961F9 100%)`,
          backgroundColor: "#FF00000",
          paddingTop: "10px",
          paddingBottom: "10px",
          textShadow: "0 1px 1px 1px #666",
          boxShadow: "0px 1px 15px 1px rgba(69, 65, 78, 0.08)",
          position: "relative",
          borderRadius: "15px",
          marginRight: "12px",
        };
      }
      if (this.gradient_type == "ocean1") {
        return {
          backgroundImage: `linear-gradient(135deg, #2AFADF 10%, #4C83FF 100%)`,
          backgroundColor: "#FF00000",
          paddingTop: "10px",
          paddingBottom: "10px",
          textShadow: "0 1px 1px 1px #666",
          boxShadow: "0px 1px 15px 1px rgba(69, 65, 78, 0.08)",
          position: "relative",
          borderRadius: "15px",
          marginRight: "12px",
        };
      }
      if (this.gradient_type == "ocean2") {
        return {
          backgroundImage: `linear-gradient(135deg, #FFD3A5 10%, #FD6585 100%)`,
          backgroundColor: "#FF00000",
          paddingTop: "10px",
          paddingBottom: "10px",
          textShadow: "0 1px 1px 1px #666",
          boxShadow: "0px 1px 15px 1px rgba(69, 65, 78, 0.08)",
          position: "relative",
          borderRadius: "15px",
          marginRight: "12px",
        };
      }
      if (this.gradient_type == "ocean3") {
        return {
          backgroundImage: `linear-gradient(135deg, #ABDCFF 10%, #5961F9 100%)`,
          backgroundColor: "#FF00000",
          paddingTop: "10px",
          paddingBottom: "10px",
          textShadow: "0 1px 1px 1px #666",
          boxShadow: "0px 1px 15px 1px rgba(69, 65, 78, 0.08)",
          position: "relative",
          borderRadius: "15px",
          marginRight: "12px",
        };
      }
      if (this.gradient_type == "ocean4") {
        return {
          backgroundImage: `linear-gradient(135deg, #FF416C 10%, #FF4B2B 100%)`,
          backgroundColor: "#FF00000",
          paddingTop: "10px",
          paddingBottom: "10px",
          textShadow: "0 1px 1px 1px #666",
          boxShadow: "0px 1px 15px 1px rgba(69, 65, 78, 0.08)",
          position: "relative",
          borderRadius: "15px",
          marginRight: "12px",
        };
      }
      if (this.gradient_type == "ocean5") {
        return {
          backgroundImage: `linear-gradient(135deg, #b1ea4d 10%, #73AA0A 100%)`,
          backgroundColor: "#FF00000",
          paddingTop: "10px",
          paddingBottom: "10px",
          textShadow: "0 1px 1px 1px #666",
          boxShadow: "0px 1px 15px 1px rgba(69, 65, 78, 0.08)",
          position: "relative",
          borderRadius: "15px",
          marginRight: "12px",
        };
      }
      if (this.gradient_type == "ocean6") {
        return {
          backgroundImage: `linear-gradient(135deg, #42e695 10%, #3bb2b8 100%)`,
          backgroundColor: "#FF00000",
          paddingTop: "10px",
          paddingBottom: "10px",
          textShadow: "0 1px 1px 1px #666",
          boxShadow: "0px 1px 15px 1px rgba(69, 65, 78, 0.08)",
          position: "relative",
          borderRadius: "15px",
          marginRight: "12px",
        };
      }
      if (this.gradient_type == "ocean7") {
        return {
          backgroundImage: `linear-gradient(135deg, #fad961 10%, #f76b1c 100%)`,
          backgroundColor: "#FF00000",
          paddingTop: "10px",
          paddingBottom: "10px",
          textShadow: "0 1px 1px 1px #666",
          boxShadow: "0px 1px 15px 1px rgba(69, 65, 78, 0.08)",
          position: "relative",
          borderRadius: "15px",
          marginRight: "12px",
        };
      }
      if (this.gradient_type == "ocean8") {
        return {
          backgroundImage: `linear-gradient(135deg, #f65599 10%, #4d0316 100%)`,
          backgroundColor: "#FF00000",
          paddingTop: "10px",
          paddingBottom: "10px",
          textShadow: "0 1px 1px 1px #666",
          boxShadow: "0px 1px 15px 1px rgba(69, 65, 78, 0.08)",
          position: "relative",
          borderRadius: "15px",
          marginRight: "12px",
        };
      }
      if (this.gradient_type == "ocean9") {
        return {
          backgroundImage: `linear-gradient(135deg, #E3E3E3 10%, #5D6874 100%)`,
          backgroundColor: "#FF00000",
          paddingTop: "10px",
          paddingBottom: "10px",
          textShadow: "0 1px 1px 1px #666",
          boxShadow: "0px 1px 15px 1px rgba(69, 65, 78, 0.08)",
          position: "relative",
          borderRadius: "15px",
          marginRight: "12px",
        };
      }
    },
  },
};
</script>
<style scoped>


.py-2 {
  padding-bottom: 0.5rem !important;
}
</style>
