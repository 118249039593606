<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />

    <div class="mt-30">
      <div class="lp-pg-generated-password">
        <form id="GENERATED-PASSWORD-FORM">
          <input :value="uuid" id="GENERATED-PASSWORD" class="lp-pg-generated-password__input" type="text" />
        </form>
        <div class="lp-pg-generated-password__icon-wrapper">
          <span class="copy-button" v-clipboard:copy="uuid" v-clipboard:success="onCopy" v-clipboard:error="onError">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-copy" width="36" height="36"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="8" y="8" width="12" height="12" rx="2" />
              <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
            </svg>
          </span>
          <span @click="refresh">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-refresh" width="36" height="36"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
              <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
            </svg></span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { v4 as generateUUID } from "uuid";
import { useToast } from "vue-toastification";
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";

export default {
  name: "UUIDGenerator",
  components: {
    ToolHeader

  },
  props: {
    msg: String,
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast };
  },
  mounted() {
    document.title = "Tools - UUID Generator";
  },
  created() { },
  data() {
    return {
      uuid: generateUUID(),
      curentComponent: tools.find((tool) => tool.id === "uuidGenerator"),

    };
  },
  methods: {
    refresh() {
      this.uuid = generateUUID();
    },

    onCopy: function () {
      this.toast.success("Copied", {
        timeout: 2000,
      });
    },
    onError: function (e) {
      this.toast.error("Failed to copy: " + e, {
        timeout: 2000,
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
.lp-pg-generated-password__input {
  width: 650px;
}

.icon-tabler:hover {
  transform: scale(1.1);
}
</style>