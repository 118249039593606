
<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />
    <div class="container">
      <picture-input
        ref="pictureInput"
        width="600"
        height="600"
        accept="image/jpeg,image/png"
        size="10"
        :removable="true"
        button-class="btn btn-blue"
        remove-button-class="btn btn-red"
        :toggleAspectRatio="true"
        :autoToggleAspectRatio="true"
        @change="onChange"
      >
      </picture-input>
    </div>
    <div class="exif-details-card">
      <h2>Details</h2>
      <div v-if="exifData != null">
        <div
          class="details"
          v-if="exifData.Make != null && exifData.Make != undefined"
        >
          <b>Camera: </b> {{ exifData.Make.description.toUpperCase() }}
          {{ exifData.Model.description }}
        </div>

        <div
          class="details"
          v-if="exifData.DateTime != null && exifData.DateTime != undefined"
        >
          <b>Date: </b> {{ exifData.DateTime.description.split(" ")[0] }}
        </div>
        <div
          class="details"
          v-if="exifData.DateTime != null && exifData.DateTime != undefined"
        >
          <b>Time: </b> {{ exifData.DateTime.description.split(" ")[1] }}
        </div>
        <div class="details">
          <b>Size: </b> {{ exifData["Image Width"].value }} x
          {{ exifData["Image Height"].value }}
        </div>
        <div
          class="details"
          v-if="
            exifData.GPSLatitude != null && exifData.GPSLatitude != undefined
          "
        >
          <b>Latitude: </b> {{ exifData.GPSLatitude.description }}
        </div>
        <div
          class="details"
          v-if="
            exifData.GPSLongitude != null && exifData.GPSLongitude != undefined
          "
        >
          <b>Longitude: </b> {{ exifData.GPSLongitude.description }}
        </div>
        <div
          class="map-details-container"
          v-if="
            exifData.GPSLongitude != null && exifData.GPSLongitude != undefined
          "
        >
          <leaflet-map
            :initial-coordinates="[
              exifData.GPSLatitude.description,
              exifData.GPSLongitude.description,
            ]"
            :latitude-ref="'South latitude'"
            :markers="markers"
          ></leaflet-map>
        </div>
      </div>
      <div v-else>Please upload a picture to see the details</div>
    </div>
  </div>
</template>


<script>
import ExifReader from "exifreader";
import PictureInput from "@/components/PictureInput";
import Map from "@/components/Map";
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";

export default {
  name: "EXIF",
  components: {
    PictureInput,
    LeafletMap: Map,
    ToolHeader
  },
  mounted() {
    document.title = "Tools - EXIF Info";
  },
  props: {
    msg: String,
  },
  created() {},
  data() {
    return {
      exifData: null,
      markers: [],
      curentComponent: tools.find((tool) => tool.id === "exifInformation"),

    };
  },
  methods: {
    onChange(image) {
      if (image) {
        this.image = image;
        ExifReader.load(this.$refs.pictureInput.file).then((result) => {
          this.exifData = result;
          if (
            this.exifData["GPSLatitudeRef"] != null &&
            this.exifData["GPSLatitudeRef"] != undefined
          ) {
            if (
              this.exifData["GPSLatitudeRef"].description == "South latitude"
            ) {
              this.exifData.GPSLatitude.description =
                -this.exifData.GPSLatitude.description;
            }
            if (
              this.exifData["GPSLongitudeRef"].description == "West longitude"
            ) {
              this.exifData.GPSLongitude.description =
                -this.exifData.GPSLongitude.description;
            }

            this.markers.push([
              this.exifData.GPSLatitude.description,
              this.exifData.GPSLongitude.description,
            ]);
          }

          console.log(this.exifData);
        });
      } else {
        console.log("FileReader API not supported: use the <form>, Luke!");
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.exif-details-card {
  max-width: 600px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.map-details-container {
  max-width: 600px;
  height: 300px;
}
</style>