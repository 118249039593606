<template>
  <div class="container">
    <h1>All Tools</h1>
    <p class="description">
      This is a collection of tools that I often need to use during my work.
      <br />
      I decided to create a place where it can all be in one place
    </p>

    <div class="card-row">
      <div v-for="tool in tools" :key="tool.name">
        <gradient-card
          @click="goToPage(tool.link)"
          class="card-col"
          :title="tool.name"
          :sub_title="tool.short_description"
          :gradient_type="'ocean' + Math.floor(Math.random() * 8)"
          :color="'#FFFFFF'"
        ></gradient-card>
      </div>
    </div>
  </div>
</template>

<script>
import GradientCard from "@/components/GradientCard";
import { tools } from "@/data/tools.js";

export default {
  name: "AllTools",
  components: {
    GradientCard,
  },
  mounted() {
    document.title = "Tools - Home";
  },
  data() {
    return {
      tools: tools,
    };
  },
  methods: {
    goToPage(id) {
      this.$router.push(id).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>

<style scoped>
.intro {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 50%;
  max-height: 25%;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding: 20px 50px 20px 50px;
  border-radius: 8px;
}

.card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-col {
  flex: 1 0 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}
</style>
