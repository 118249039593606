<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />


    <div class="intro">
      Encoded polylines are great for reducing the size of a coordinates array,
      for storage or to show on a map (Google maps, Leaflet, Mapbox). <br />
      This polyline encoder can create an encoded polyline from an Array of
      Coordinates <span class="highlight">[lat,lng]</span>
    </div>
    <div class="code-container">
      <div class="left">
        <h3>Array</h3>
        <div class="error-message" v-if="error">
          ERROR: The provided code is not an array.
        </div>
        <codemirror id="json" :value="this.geoJson" :options="this.cmOptions" @input="this.onCmCodeChange" />
        <div class="btn-green" @click="encode">Encode</div>
        <div class="btn-orange" v-clipboard:copy="geoJson" v-clipboard:success="onCopy" v-clipboard:error="onError">
          Copy to clipboard
        </div>
      </div>
      <div class="right">
        <h3>Polyline encoded</h3>
        <codemirror :value="polyline" :options="cmOptions" @input="onCmCodeChange2" />
        <div class="btn-red" @click="decode">Decode</div>
        <div class="btn-orange" v-clipboard:copy="polyline" v-clipboard:success="onCopy" v-clipboard:error="onError">
          Copy to clipboard
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";
import codemirror from "codemirror-editor-vue3";

/* eslint-disable */

// import base style
import "codemirror/lib/codemirror.css";
// language
import "codemirror/mode/javascript/javascript.js";

// theme css
import "codemirror/theme/monokai.css";
import "codemirror/theme/ayu-mirage.css";

// require active-line.js
import "codemirror/addon/selection/active-line.js";

// styleSelectedText
import "codemirror/addon/selection/mark-selection.js";
import "codemirror/addon/search/searchcursor.js";

// hint
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/javascript-hint.js";
import "codemirror/addon/selection/active-line.js";

// highlightSelectionMatches
import "codemirror/addon/scroll/annotatescrollbar.js";
import "codemirror/addon/search/matchesonscrollbar.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/match-highlighter.js";

// keyMap
import "codemirror/mode/clike/clike.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/comment/comment.js";
import "codemirror/addon/dialog/dialog.js";
import "codemirror/addon/dialog/dialog.css";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/search.js";
import "codemirror/keymap/sublime.js";

// foldGutter
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/brace-fold.js";
import "codemirror/addon/fold/comment-fold.js";
import "codemirror/addon/fold/foldcode.js";
import "codemirror/addon/fold/foldgutter.js";
import "codemirror/addon/fold/indent-fold.js";
import "codemirror/addon/fold/markdown-fold.js";
import "codemirror/addon/fold/xml-fold.js";

// autorefresh
import "codemirror/addon/display/autorefresh.js";
var beautify_js = require("js-beautify");
var polyUtil = require("polyline-encoded");

export default {
  name: "Encoder",
  components: {
    codemirror,
    ToolHeader
  },
  computed: {
    polyEnc() {
      let poly = this.polyline;
      return poly;
    },
    error() {
      let error = false;
      let code = this.geoJson;
      try {
        JSON.parse(code);
      } catch (ex) {
        error = true;
      }

      return error;
    },
  },
  data() {
    return {
      cmOptions: {
        autoRefresh: true,
        tabSize: 4,
        styleActiveLine: false,
        lineNumbers: true,
        styleSelectedText: false,
        line: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        mode: `text/javascript`,
        hintOptions: {
          completeSingle: false,
        },
        keyMap: "sublime",
        matchBrackets: true,
        showCursorWhenSelecting: true,
        // theme: "monokai",
        theme: "ayu-mirage",
        extraKeys: { Ctrl: "autocomplete" },
      },
      geoJson: `[
    [
      10.155057907104492,
      56.138352670069864
    ],
    [
      10.151925086975098,
      56.14643406792885
    ],
    [
      10.155143737792967,
      56.147079550100564
    ]
  ]
        `,
      polyline: "o_gbf@cwnyKscaV|rOpihCjnhDnnnMyaWjq|Holk@ria@{q}E",
      curentComponent: tools.find((tool) => tool.id === "polylineEncoder"),

    };
  },
  methods: {
    onCmCodeChange(newCode) {
      var formattedJSON = beautify_js(newCode, {
        indent_size: "2",
      });
      this.geoJson = formattedJSON;
    },
    onCmCodeChange2(newCode) {
      this.polyline = newCode;
    },
    encode() {
      let parsed = JSON.parse(this.geoJson);
      var code = polyUtil.encode(parsed);
      this.polyline = code;
    },

    decode() {
      var latLngArr = [];

      var latlngs = polyUtil.decode(this.polyline);
      //This is A TEMP solution --- apparently the coordinates are coming in wrong from the server!
      latlngs.forEach((latlng) => {
        var lat = latlng[0];
        var lng = latlng[1];
        var newArr = [];
        newArr.push(lat);
        newArr.push(lng);
        latLngArr.push(newArr);
      });

      var formattedJSON = beautify_js(JSON.stringify(latLngArr), {
        indent_size: "2",
      });
      this.geoJson = formattedJSON;
    },
    onCopy: function (e) {
      alert("You just copied the following text to the clipboard: " + e.text);
    },
    onError: function (e) {
      alert("Failed to copy the text to the clipboard");
      console.log(e);
    },
  },
};
</script>

<style scoped>
/* Container for flexboxes */
.highlight {
  font-family: "Courier New", Courier, monospace;
  color: #fc0df6;
}

.intro {
  font-size: calc(1.5rem + 0.25vh);
}

.code-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.left {
  flex: 1 0 40vw;
  max-width: 40vw;
  height: 70vh;
  margin: 10px;
}

.right {
  flex: 1 0 40vw;
  max-width: 40vw;
  height: 70vh;
  margin: 10px;
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
  .code-container {
    flex-direction: column;
  }

  .left {
    flex: 1 0;
    width: 90vw;
    max-width: 90vw;
  }

  .right {
    flex: 1 0;
    width: 90vw;
    max-width: 90vw;
  }
}

.error-message {
  background-color: red;
  font-weight: 500;
  color: white;
}

.btn-orange {
  margin: 10px;
  /* background-color: #d99738; */
  border: 2px solid #d99738;
  color: var(--font-color);
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
}

.btn-orange:hover {
  background-color: #d99738;
}

.btn-red {
  margin: 10px;
  /* background-color: #f05e57; */
  border: 2px solid #f05e57;
  color: var(--font-color);
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
}

.btn-red:hover {
  background-color: #fd6b64;
}

.btn-green {
  margin: 10px;
  /* background-color: #94D931; */
  border: 2px solid #94d931;
  color: var(--font-color);
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
}

.btn-green:hover {
  background-color: #94d931;
}
</style>
<style>
.CodeMirror {
  border: 1px solid #eee;
  /* height: 100%; */
  min-height: 500px;
  text-align: left !important;
}
</style>