<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />

    <div class="mt-30">
      <label>Number to Convert</label>
      <div class="lp-pg-generated-password">
        <form id="GENERATED-PASSWORD-FORM">
          <input
            v-model="numberToConvert"
            id="GENERATED-PASSWORD"
            class="lp-pg-generated-password__input"
            type="text"
          />
        </form>
        <select class="form-control" name="template" v-model="selected">
          <option
            v-for="option in options"
            v-bind:value="option"
            :key="option.value"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
      <div v-for="number in options" :key="number.value">
        <div class="input-box">
          <div class="swatch-info-header">
            <span class="color-text">{{ number.name }}</span>
          </div>
          <div class="swatch-info-body">
            <span class="color-text">{{
              this.convertNumber(this.numberToConvert, this.selected, number)
            }}</span>
            <div class="copy-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-copy"
                width="16"
                height="16"
                viewBox="0 0 20 20"
                stroke-width="1.5"
                stroke="#fc0df6"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="8" y="8" width="12" height="12" rx="2" />
                <path
                  d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { arabicToRoman, romanToArabic, convertBase } from "@/utils/numberUtil";
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";

export default {
  name: "NumberConverter",
  components: {
    ToolHeader
  },
  props: {
    msg: String,
  },
  mounted() {
    document.title = "Tools - Number Converter";
  },
  created() {},
  data() {
    return {
      selected: {
        name: "Arabic",
        value: "arabic",
        base: 10,
      },
      numberToConvert: 42,
      options: [
        {
          name: "Arabic",
          value: "arabic",
          base: 10,
        },
        {
          name: "Roman",
          value: "roman",
          base: "roman",
        },
        {
          name: "Hex (Base16)",
          value: "hex",
          base: 16,
        },
        {
          name: "Octal (Base8)",
          value: "octal",
          base: 8,
        },
        {
          name: "Binary (Base2)",
          value: "binary",
          base: 2,
        },
      ],
      curentComponent: tools.find((tool) => tool.id === "numberConverter"),
    };
  },
  methods: {
    convertNumber(number, from, to) {
      switch (to.value) {
        case "arabic":
          if (from.value == "roman") {
            return romanToArabic(number);
          } else {
            return convertBase(number.toString(), from.base, to.base);
          }
        case "roman":
          if (from.value == "roman") {
            return number;
          } else if (from.value == "arabic") {
            return arabicToRoman(number);
          } else {
            let arabic = convertBase(number.toString(), from.base, 10);
            return arabicToRoman(arabic);
          }

        case "hex":
        case "binary":
        case "octal":
          if (from.value == "roman") {
            var arabic = romanToArabic(number);
            return convertBase(arabic.toString(), 10, to.base);
          } else {
            return convertBase(number.toString(), from.base, to.base);
          }
        default:
          break;
      }
    },
  },
  computed: {
    romanNumber() {
      let number = this.numberToConvert;
      return arabicToRoman(number);
    },
  },
};
</script>

<style>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>