<template>
  <table class="styled-table">
    <thead>
      <tr>
        <th v-for="(headerField, index) in columns" :key="index">
          {{ headerField.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in rows" :key="index">
        <td v-for="(field, key) in row" :key="key">
          {{ field }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "DataTable",
  props: {
    columns: {
      type: Array,
    },
    rows: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.styled-table {
  border-collapse: collapse;
  border-radius: 10px;
  margin: 25px 10px;
  font-size: 0.9em;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  background: #ffffff;
}

.styled-table thead tr {
  background-color: #11101D;
  border-radius: 10px;
  color: #FFF;
  text-transform: capitalize;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
</style>