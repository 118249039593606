<template>
    <div>
        <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />


        <!-- <div class="intro">
            URL Encode/Decode
        </div> -->
        <div class="code-container">
            <div class="left">
                <p>String to encode</p>
                <textarea v-model="stringToEncode"></textarea>
                <p>Encoded string</p>
                <textarea v-model="encodedUri"></textarea>

            </div>
            <div class="right">
                <p>String to decode</p>
                <textarea v-model="stringToDecode"></textarea>
                <p>Encoded string</p>
                <textarea v-model="decodedUri"></textarea>

            </div>
        </div>
    </div>
</template>

<script>
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";

import codemirror from "codemirror-editor-vue3";

/* eslint-disable */

// import base style
import "codemirror/lib/codemirror.css";
// language
import "codemirror/mode/javascript/javascript.js";

// theme css
import "codemirror/theme/monokai.css";
import "codemirror/theme/ayu-mirage.css";

// require active-line.js
import "codemirror/addon/selection/active-line.js";

// styleSelectedText
import "codemirror/addon/selection/mark-selection.js";
import "codemirror/addon/search/searchcursor.js";

// hint
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/javascript-hint.js";
import "codemirror/addon/selection/active-line.js";

// highlightSelectionMatches
import "codemirror/addon/scroll/annotatescrollbar.js";
import "codemirror/addon/search/matchesonscrollbar.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/match-highlighter.js";

// keyMap
import "codemirror/mode/clike/clike.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/comment/comment.js";
import "codemirror/addon/dialog/dialog.js";
import "codemirror/addon/dialog/dialog.css";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/search.js";
import "codemirror/keymap/sublime.js";

// foldGutter
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/brace-fold.js";
import "codemirror/addon/fold/comment-fold.js";
import "codemirror/addon/fold/foldcode.js";
import "codemirror/addon/fold/foldgutter.js";
import "codemirror/addon/fold/indent-fold.js";
import "codemirror/addon/fold/markdown-fold.js";
import "codemirror/addon/fold/xml-fold.js";

// autorefresh
import "codemirror/addon/display/autorefresh.js";
var beautify_js = require("js-beautify");
var polyUtil = require("polyline-encoded");

export default {
    name: "Encoder",
    components: {
        codemirror,
        ToolHeader
    },
    computed: {
        encodedUri() {
            return encodeURIComponent(this.stringToEncode);
        },
        decodedUri() {
            return decodeURIComponent(this.stringToDecode);
        },
    },
    data() {
        return {
            stringToEncode: "",
            stringToDecode: "",
            curentComponent: tools.find((tool) => tool.id === "urlEncoder"),


        };
    },
    methods: {
        onCopy: function (e) {
            alert("You just copied the following text to the clipboard: " + e.text);
        },
        onError: function (e) {
            alert("Failed to copy the text to the clipboard");
            console.log(e);
        },
    },
};
</script>

<style scoped>
/* Container for flexboxes */
.highlight {
    font-family: "Courier New", Courier, monospace;
    color: #fc0df6;
}

.intro {
    font-size: calc(1.5rem + 0.25vh);
}

.code-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
}

.left {
    flex: 1 0 40vw;
    max-width: 40vw;
    height: 70vh;
    margin: 10px;
}

.right {
    flex: 1 0 40vw;
    max-width: 40vw;
    height: 70vh;
    margin: 10px;
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
    .code-container {
        flex-direction: column;
    }

    .left {
        flex: 1 0;
        width: 90vw;
        max-width: 90vw;
    }

    .right {
        flex: 1 0;
        width: 90vw;
        max-width: 90vw;
    }
}

.error-message {
    background-color: red;
    font-weight: 500;
    color: white;
}

.btn-orange {
    margin: 10px;
    /* background-color: #d99738; */
    border: 2px solid #d99738;
    color: var(--font-color);
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
}

.btn-orange:hover {
    background-color: #d99738;
}

.btn-red {
    margin: 10px;
    /* background-color: #f05e57; */
    border: 2px solid #f05e57;
    color: var(--font-color);
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
}

.btn-red:hover {
    background-color: #fd6b64;
}

.btn-green {
    margin: 10px;
    /* background-color: #94D931; */
    border: 2px solid #94d931;
    color: var(--font-color);
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
}

.btn-green:hover {
    background-color: #94d931;
}
</style>
<style>
.CodeMirror {
    border: 1px solid #eee;
    /* height: 100%; */
    min-height: 500px;
    text-align: left !important;
}
</style>