export function arabicToRoman(num) {
    if (num < 1) return '';

    const lookup = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
    };
    let roman = '';
    for (const i in lookup) {
        while (num >= lookup[i]) {
            roman += i;
            num -= lookup[i];
        }
    }
    return roman;
}

export function romanToArabic(s) {
    const map = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
    return [...s].reduce((r, c, i, s) => (map[s[i + 1]] > map[c] ? r - map[c] : r + map[c]), 0);
}


export function convertBase(value, fromBase, toBase) {
    const range = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ+/'.split('');
    const fromRange = range.slice(0, fromBase);
    const toRange = range.slice(0, toBase);
    let decValue = value
        .split('')
        .reverse()
        .reduce((carry, digit, index) => {
            if (!fromRange.includes(digit)) {
                throw new Error('Invalid digit `' + digit + '` for base ' + fromBase + '.');
            }
            return (carry += fromRange.indexOf(digit) * Math.pow(fromBase, index));
        }, 0);
    let newValue = '';
    while (decValue > 0) {
        newValue = toRange[decValue % toBase] + newValue;
        decValue = (decValue - (decValue % toBase)) / toBase;
    }
    return newValue || '0';
}
