<template>
  <div>
    <h1>Epoch time converter</h1>
    <div class="input-box">
      <div class="swatch-info-header">
        Epoch time now:
        <!-- <span class="color-text">Epoch time now:</span> -->
      </div>
      <div class="swatch-info-body">
        <span class="color-text">{{ this.epoch }}</span>
      </div>
    </div>
    <div class="input-box">
      <div class="swatch-info-header">
        <span class="color-text">Epoch to convert:</span>
      </div>
      <div class="swatch-info-body">
        <input class="color-text mr-15 form-control" v-model="epochToConvert" />
        <span class="color-text">{{
          this.getDateFromEpoch(epochToConvert)
        }}</span>
      </div>
    </div>
    <div class="input-box">
      <div class="swatch-info-header">
        <span class="color-text">Date to epoch:</span>
      </div>
      <div class="swatch-info-body">
        <Datepicker v-model="dateToConvert"></Datepicker>
        <span class="color-text">{{
          this.getEpochTimeSecondsFromDate(dateToConvert)
        }}</span>
      </div>
    </div>
  </div>
</template>
  
  
  <script>
/* eslint-disable */
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "Epoch",
  components: { Datepicker },
  mounted() {
    document.title = "Tools - Epoch";
    this.$options.interval = setInterval(this.getEpochTime, 1000);
  },
  created() {},
  data() {
    return {
      epoch: "12586987845",
      epochToConvert: this.getEpochTimeSeconds(),
      dateToConvert: new Date(),
    };
  },
  methods: {
    getEpochTime() {
      const now = new Date();
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);

      this.epoch = secondsSinceEpoch;
    },
    getEpochTimeSeconds() {
      const now = new Date();
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);

      return secondsSinceEpoch;
    },
    getEpochTimeSecondsFromDate(date) {
      const now = new Date(date);
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);

      return secondsSinceEpoch;
    },
    getDateFromEpoch(epoch) {
      var d = new Date(epoch * 1000);
      return d;
    },
  },
  computed: {},
};
</script>
  
  <style scoped>
.swatch-info-header {
  text-align: center;
  min-height: 60px;
}
.swatch-info-body {
  text-align: center;
  min-height: 60px;
  max-height: 60px;
}

.form-control {
  border: 4px solid #676767;
  width: 275px;
  display: inline;
  height: 50px;
  text-align: center;
  font-size: 140%;
}
</style>