<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />

    <div class="code-container">
      <div class="left">
        <div class="header-row">
          <div class="checkbox-row">
            <input-checkbox
              :id="'prependDataAttr'"
              :initial-state="prependDataAttr"
              :text="'Prepend Data Attribute'"
              @change-value="(n) => (prependDataAttr = n)"
            ></input-checkbox>
          </div>
        </div>
        <textarea v-model="orginalText" class="array"></textarea>
        <button
          class="btn-green"
          @click="this.base64 = this.convertToBase64(this.orginalText)"
        >
          Convert to base64
        </button>
      </div>
      <div class="right">
        <div class="header-row"></div>
        <textarea v-model="base64" class="array"></textarea>
        <button
          class="btn-green"
          @click="this.orginalText = this.convertToText(this.base64)"
        >
          Convert to text
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import InputCheckbox from "@/components/Checkbox";
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";

export default {
  components: {
    InputCheckbox,
    ToolHeader

  },
  name: "Base64Encode",
  created() {},
  mounted() {
    document.title = "Tools - Base64 Encode";
  },
  data() {
    return {
      prependDataAttr: false,
      orginalText:
        "The Text to Base64 converter is a simple encoder tool that allows you to convert online text to Base64",
      base64: "",
      curentComponent: tools.find((tool) => tool.id === "base64Encode"),

    };
  },
  methods: {
    convertToText(text) {
      return atob(text);
    },
    convertToBase64(text) {
      if (this.prependDataAttr) {
        return "data:text/plain;base64," + btoa(text);
      }
      return btoa(text);
    },
  },
  computed: {},
};
</script>

<style scoped>
.header-row {
  min-height: 50px;
}

.checkbox-row {
  display: flex;
}
</style>