<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />

    <div>
      <input class="mr-15 form-control" v-model="text" />
      <div v-for="algo in algoNames" :key="algo">
        <div class="input-box">
          <div class="swatch-info-header">
            <span class="color-text">{{ algo }}</span>
          </div>
          <div class="swatch-info-body">
            <span class="color-text">{{ this.hashText(algo, text) }}</span>
            <div
              class="copy-button"
              v-clipboard:copy="this.hashText(algo, text)"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-copy"
                width="16"
                height="16"
                viewBox="0 0 20 20"
                stroke-width="1.5"
                stroke="#fc0df6"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="8" y="8" width="12" height="12" rx="2" />
                <path
                  d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";

import { useToast } from "vue-toastification";

import {
  MD5,
  SHA1,
  SHA256,
  SHA224,
  SHA512,
  SHA384,
  SHA3,
  RIPEMD160,
} from "crypto-js";

const algos = {
  MD5,
  SHA1,
  SHA256,
  SHA224,
  SHA512,
  SHA384,
  SHA3,
  RIPEMD160,
};

export default {
  name: "HashText",
  components: {
    ToolHeader
  },
  props: {},
  mounted() {
    document.title = "Tools - Hash Text";
  },
  created() {

  },
  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      text: "lorem ipsum",
      algoNames: Object.keys(algos),
      curentComponent: tools.find((tool) => tool.id === "hashText"),

    };
  },
  methods: {
    hashText(algo, value) {
      return algos[algo](value).toString();
    },
    onCopy: function () {
      this.toast.success("Copied", {
        timeout: 2000,
      });
    },
    onError: function (e) {
      this.toast.error("Failed to copy: " + e, {
        timeout: 2000,
      });
    },
  },
};
</script>

<style scoped>
h1 {
  text-decoration: underline;
}


.color-text {
  overflow: hidden;
  margin-left: 5px;
}

.copy-button {
  margin-left: 5px;
}

.form-control {
  border: 4px solid #676767;
  width: 500px;
  display: inline;
  height: 55px;
  text-align: center;
  font-size: 140%;
}
</style>