<template>
  <div>
    <VueSidebarMenuAkahon :menuTitle="'Tools'" :menuItems="filteredItems" @search-input-emit="searchChanges"
      isExitButton:="false" />
    <div class="content">
      <ninja-keys @selected="selected" @change="change" :placeholder="placeholder" :data="hotkeys"></ninja-keys>
      <!-- <div class="content-header">
        <div data-v-ae934b0a="" data-v-5113af8a="" flex-1=""><button data-v-147876cf="" data-v-ae934b0a=""
            class="c-button" w-full="" important:justify-start=""
            style="--9a468bfe: 14px; --09441655: 34px; --e1cab870: #333639; --645976e7: rgba(46, 51, 56, 0.05); --cea95e96: rgba(46, 51, 56, 0.09); --369e4b1b: rgba(46, 51, 56, 0.22); --c8f65826: #18a058;"><span
              data-v-ae934b0a="" flex="" items-center="" gap-3="" op-40=""><svg data-v-ae934b0a="" viewBox="0 0 24 24"
                width="1.2em" height="1.2em">
                <path fill="currentColor"
                  d="M9.5 3A6.5 6.5 0 0 1 16 9.5c0 1.61-.59 3.09-1.56 4.23l.27.27h.79l5 5l-1.5 1.5l-5-5v-.79l-.27-.27A6.516 6.516 0 0 1 9.5 16A6.5 6.5 0 0 1 3 9.5A6.5 6.5 0 0 1 9.5 3m0 2C7 5 5 7 5 9.5S7 14 9.5 14S14 12 14 9.5S12 5 9.5 5Z">
                </path>
              </svg> Search <span data-v-ae934b0a="" hidden="" flex-1="" border="" border-current="" border-op-40=""
                rounded="" border-solid="" px-5px="" py-3px="" sm:inline="">Cmd&nbsp;+&nbsp;K
              </span></span></button></div>
      </div> -->
      <!-- <div>
        <li><i class="bx bx-search"></i><input type="text" placeholder="Search..."><span class="tooltip">Search</span>
        </li>
      </div> -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { RouterView } from "vue-router";
import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";
import { tools } from "@/data/tools.js";
import "ninja-keys";
import router from './router'


export default {
  name: "App",
  components: {
    VueSidebarMenuAkahon,
    RouterView,
  },
  created() { },
  data() {
    return {
      items: [...tools],
      searchTerm: "",
      placeholder: "Placeholder prop test",
      // hotkeys: [
      //   {
      //     id: "Home",
      //     title: "Open Home",
      //     hotkey: "cmd+h",
      //     mdIcon: "home",
      //     handler: () => {
      //       console.log("navigation to home");
      //     },
      //   },
      //   {
      //     id: "Open Projects",
      //     title: "Open Projects",
      //     hotkey: "cmd+p",
      //     mdIcon: "apps",
      //     handler: () => {
      //       console.log("navigation to projects");
      //     },
      //   },
      //   {
      //     id: "array",
      //     title: "array",
      //     // hotkey: "cmd+p",
      //     mdIcon: "apps",
      //     handler: () => {
      //       router.push('/array')
      //     },
      //   },
      //   {
      //     id: "Theme",
      //     title: "Change theme...",
      //     mdIcon: "desktop_windows",
      //     children: [
      //       {
      //         id: "Light Theme",
      //         title: "Change theme to Light",
      //         mdIcon: "light_mode",
      //         handler: () => {
      //           console.log("theme light");
      //         },
      //       },
      //       {
      //         id: "Dark Theme",
      //         title: "Change theme to Dark",
      //         mdIcon: "dark_mode",
      //         keywords: "lol",
      //         handler: () => {
      //           console.log("theme dark");
      //         },
      //       },
      //     ],
      //   },
      // ],
      hotkeys: tools.map(tool => ({
        id: tool.id,
        title: tool.name,
        mdIcon: this.getIcon(tool.id),
        handler: () => {
          router.push(tool.link);
        },
      })),
    };
  },
  methods: {
    searchChanges(event) {
      this.searchTerm = event;
    },
    selected(event) {
      console.log("selected", event.detail);
    },
    change(event) {
      console.log("change", event.detail);
    },
    getIcon(toolId) {
      switch (toolId) {
        case 'allTools':
          return 'apps';
        case 'arrayThis':
          return 'view_list';
        case 'base64Encode':
          return 'code';
        case 'countryInfo':
          return 'public';
        case 'colorGenerator':
          return 'palette';
        case 'csvToJson':
          return 'table_chart';
        case 'csvTable':
          return 'grid_on';
        case 'deviceInformation':
          return 'devices';
        case 'exifInformation':
          return 'image_search';
        case 'epochConverter':
          return 'schedule';
        case 'hashText':
          return 'text_fields';
        case 'htmlEscape':
          return 'code';
        case 'jsonToCsv':
          return 'table_chart';
        case 'numberConverter':
          return 'calculate';
        case 'passwordGenerator':
          return 'vpn_key';
        case 'polylineEncoder':
          return 'timeline';
        case 'unitConverter':
          return 'swap_horiz';
        case 'urlEncoder':
          return 'link';
        case 'uuidGenerator':
          return 'fingerprint';
        default:
          return 'help_outline';
      }
    }

  },
  computed: {
    filteredItems() {
      let searchTerm = this.searchTerm;
      let allItems = this.items;
      if (searchTerm == "") {
        return allItems;
      } else {
        return allItems.filter((item) => item.name.indexOf(searchTerm) != -1);
      }
    },
  },
};
</script>

<style>
@import "./style/theme.css";
@import "./style/tools.css";
@import "./style/card.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.profile {
  display: none;
}

.c-button {
  line-height: 1;
  font-family: inherit;
  font-size: var(--9a468bfe);
  border: none;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  height: var(--09441655);
  font-weight: 400;
  color: var(--e1cab870);
  padding: 0 14px;
  border-radius: 4px;
  transition: background-color cubic-bezier(.4, 0, .2, 1) .3s;
  background-color: var(--645976e7);
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.content-header {
  display: flex;
  width: 100;
}

.bx {
  font-family: 'boxicons' !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  display: inline-block;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: var(--secondary-color);
  color: var(--icons-color);
}

.tooltip {
  display: none;
}
</style>
