<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />

    <div>
      <div class="code-container">
        <div class="left">
          <div class="header-row">
            <div>JSON to convert</div>
            <file-upload @result="(result) => (json = result)"></file-upload>
          </div>
          <div class="error-message" v-if="error">
            ERROR: The provided code is not valid JSON.
          </div>
          <codemirror id="json" :value="this.json" :options="this.cmOptions" @input="this.onCmCodeChange" />
          <button class="btn-green" style="color: black" @click="onCmCodeChange(json)">
            Format code
          </button>
          <button class="btn-orange" @click="convertToCSV(json)">
            Convert
          </button>
        </div>
        <div class="right">
          <div class="header-row">
            <div>CSV Output</div>
          </div>
          <textarea v-model="csv" class="array"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import InputCheckbox from "@/components/Checkbox";
import FileUpload from "@/components/FileUpload";
import ToolHeader from "@/components/ToolHeader";

import { tools } from "@/data/tools.js";
import { Parser } from "@json2csv/plainjs";

import codemirror from "codemirror-editor-vue3";

/* eslint-disable */

// import base style
import "codemirror/lib/codemirror.css";
// language
import "codemirror/mode/javascript/javascript.js";

// theme css
import "codemirror/theme/monokai.css";
import "codemirror/theme/ayu-mirage.css";

// require active-line.js
import "codemirror/addon/selection/active-line.js";

// styleSelectedText
import "codemirror/addon/selection/mark-selection.js";
import "codemirror/addon/search/searchcursor.js";

// hint
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/javascript-hint.js";
import "codemirror/addon/selection/active-line.js";

// highlightSelectionMatches
import "codemirror/addon/scroll/annotatescrollbar.js";
import "codemirror/addon/search/matchesonscrollbar.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/match-highlighter.js";

// keyMap
import "codemirror/mode/clike/clike.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/comment/comment.js";
import "codemirror/addon/dialog/dialog.js";
import "codemirror/addon/dialog/dialog.css";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/search.js";
import "codemirror/keymap/sublime.js";

// foldGutter
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/brace-fold.js";
import "codemirror/addon/fold/comment-fold.js";
import "codemirror/addon/fold/foldcode.js";
import "codemirror/addon/fold/foldgutter.js";
import "codemirror/addon/fold/indent-fold.js";
import "codemirror/addon/fold/markdown-fold.js";
import "codemirror/addon/fold/xml-fold.js";

// autorefresh
import "codemirror/addon/display/autorefresh.js";
var beautify_js = require("js-beautify");

export default {
  name: "JsonToCsv",
  components: {
    // InputCheckbox,
    FileUpload,
    codemirror,
    ToolHeader
  },
  mounted() {
    document.title = "Tools - JSON to CSV";
  },
  created() { },
  data() {
    return {
      cmOptions: {
        autoRefresh: true,
        tabSize: 4,
        styleActiveLine: false,
        lineNumbers: true,
        styleSelectedText: false,
        line: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        mode: `text/javascript`,
        hintOptions: {
          completeSingle: false,
        },
        keyMap: "sublime",
        matchBrackets: true,
        showCursorWhenSelecting: true,
        // theme: "monokai",
        theme: "default",
        extraKeys: { Ctrl: "autocomplete" },
      },
      json: `[
  {
    "Year": 1997,
    "Make": "Ford",
    "Model": "E350",
    "Description": "ac abs moon",
    "Price": 3000
  },
  {
    "Year": 1999,
    "Make": "Chevy",
    "Model": "Venture Extended Edition",
    "Description": 0,
    "Price": 4900
  },
  {
    "Year": 1999,
    "Make": "Chevy",
    "Model": "Venture Extended Edition",
    "Description": "Very Large",
    "Price": 5000
  },
  {
    "Year": 1996,
    "Make": "Jeep",
    "Model": "Grand Cherokee",
    "Description": "MUST SELL!",
    "Price": 4799
  }
]`,
      csv: "",
      curentComponent: tools.find((tool) => tool.id === "jsonToCsv"),

    };
  },
  methods: {
    onCmCodeChange(newCode) {
      var formattedJSON = beautify_js(newCode, {
        indent_size: "2",
      });
      this.json = formattedJSON;
    },
    convertToCSV(myData) {
      let data = JSON.parse(myData);
      try {
        const opts = {};
        const parser = new Parser(opts);
        const csv = parser.parse(data);
        this.csv = csv;

      } catch (err) {
        console.error(err);
      }
    },
  },
  computed: {
    fields() {
      let data = JSON.parse(this.json);

      return Object.keys(data[0])
    },
    error() {

      let error = false;
      let code = this.json;
      try {
        JSON.parse(code);
      } catch (ex) {
        error = true;
      }

      return error;
    },
  },
};
</script>

<style scoped>
.header-row {
  min-height: 80px;
}

.checkbox-row {
  display: flex;
}

.error-message {
  background-color: red;
  font-weight: 500;
  color: white;
}
</style>