<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />
    <div class="code-container">
      <div class="left">
        <div>HTML to Escaped Text</div>
        <textarea v-model="rawHtmltoPlain" class="box"></textarea>
        <textarea readonly v-model="plainText" class="box"></textarea>
        <button class="btn-green" v-clipboard:copy="plainText" v-clipboard:success="onCopy" v-clipboard:error="onError">
          Copy
        </button>
      </div>
      <div class="right">
        <div>Escaped Text to HTML</div>
        <textarea v-model="plainText1" class="box"></textarea>
        <textarea readonly v-model="rawHtmltoPlain1" class="box"></textarea>
        <button class="btn-green" v-clipboard:copy="rawHtmltoPlain1" v-clipboard:success="onCopy"
          v-clipboard:error="onError">
          Copy
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import { useToast } from "vue-toastification";
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";

export default {
  name: "HTMLEscape",
  components: {
    ToolHeader
  },
  props: {
    msg: String,
  },
  mounted() {
    document.title = "Tools - HTML Escape";
  },
  created() { },
  setup() {
    // Get toast interface
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      rawHtmltoPlain: "<div>THIS IS AWESOME</div>",
      plainText1: "&lt;div&gt;THIS IS AWESOME&lt;/div&gt;",
      curentComponent: tools.find((tool) => tool.id === "htmlEscape"),

    };
  },
  methods: {
    escapeHtml(unsafe) {
      return unsafe
        .replaceAll("&", "&amp;")
        .replaceAll("<", "&lt;")
        .replaceAll(">", "&gt;")
        .replaceAll('"', "&quot;")
        .replaceAll("'", "&#039;");
    },
    unEscapeHtml(unsafe) {
      return unsafe
        .replaceAll("&amp;", "&")
        .replaceAll("&lt;", "<")
        .replaceAll("&gt;", ">")
        .replaceAll("&quot;", '"')
        .replaceAll("&#039;", "'");
    },
    onCopy: function () {
      this.toast.success("Copied", {
        timeout: 2000,
      });
    },
    onError: function (e) {
      this.toast.error("Failed to copy: " + e, {
        timeout: 2000,
      });
    },
  },
  computed: {
    plainText() {
      return this.escapeHtml(this.rawHtmltoPlain);
    },
    rawHtmltoPlain1() {
      return this.unEscapeHtml(this.plainText1);
    },
  },
};
</script>

<style scoped>
.box {
  margin-top: 10px;
  height: 50%;
}
</style>