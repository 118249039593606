<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />

    <div class="code-container">
      <div class="left">
        <div>Put in the list you want as an array</div>
        <div class="header-row">
          <div class="checkbox-row">
            <input-checkbox :id="'trim'" :initial-state="trim" :text="'Trim'"
              @change-value="(n) => (trim = n)"></input-checkbox>
            <input-checkbox :id="'removeDuplicates'" :initial-state="removeDuplicates" :text="'Remove duplicates'"
              @change-value="(n) => (removeDuplicates = n)"></input-checkbox>
          </div>
        </div>
        <textarea v-model="rawArray" class="array"></textarea>
      </div>
      <div class="right">
        <div class="info-text"><b>[raw]</b> comma separated list</div>
        <textarea v-model="commaSeparated" class="smaller"></textarea>
        <div class="info-text">
          <b>[array]</b> use in an array like
          <span class="secondary-font"> var arr = ["item"]</span>
        </div>
        <textarea v-model="asArray" class="smaller"></textarea>
        <div class="info-text"><b>[array]</b> for php and perl</div>
        <textarea v-model="asArrayPhp" class="smaller"></textarea>
      </div>
    </div>
  </div>
</template>


<script>
import InputCheckbox from "@/components/Checkbox";
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";

export default {

  name: "ToArray",
  components: {
    InputCheckbox,
    ToolHeader
  },
  props: {
    msg: String,
  },
  created() { },
  mounted() {
    document.title = "Tools - To Array";
  },
  data() {
    return {
      rawArray: "",
      trim: true,
      removeDuplicates: true,
      prefix: "",
      suffix: "",
      curentComponent: tools.find((tool) => tool.id === "arrayThis"),
    };
  },
  methods: {},
  computed: {
    commaSeparated() {
      let arr = this.rawArray ? String(this.rawArray) : '';

      if (this.trim) {
        // Split the string by spaces and newlines, filter out any empty strings
        let wordsArray = arr.split(/\s+/).filter(word => word.trim() !== '');

        // Trim each word
        arr = wordsArray.map(word => word.trim());

        // Remove duplicates if removeDuplicates is true
        if (this.removeDuplicates) {
          arr = [...new Set(arr)];
        }

        // Join the array into a comma-separated string
        arr = arr.join(',');
      } else {
        // Replace newline characters with commas
        arr = arr.replace(/\n/g, ',');

        // Remove duplicates if removeDuplicates is true
        if (this.removeDuplicates) {
          let wordsArray = arr.split(',');
          arr = [...new Set(wordsArray)].join(',');
        }
      }

      return arr;
    },
    asArray() {
      let arr = this.rawArray ? String(this.rawArray) : '';

      if (this.trim) {
        // Split the string by newlines, filter out any empty strings, and trim each line
        arr = arr.split('\n').map(line => line.trim()).filter(line => line !== '');
      } else {
        // Split the string by newlines and filter out any empty strings
        arr = arr.split('\n').filter(line => line !== '');
      }

      return JSON.stringify(arr);
    },
    asArrayPhp() {
      let arr = this.rawArray ? String(this.rawArray) : '';

      if (this.trim) {
        // Split the string by newlines, trim each line, and filter out any empty strings
        arr = arr.split('\n').map(line => line.trim()).filter(line => line !== '');
      } else {
        // Split the string by newlines and filter out any empty strings
        arr = arr.split('\n').filter(line => line !== '');
      }

      return (
        "array" +
        JSON.stringify(arr)
          .replace("[", "(")
          .replace("]", ")")
      );
    }
  },
};
</script>

<style scoped>
textarea {
  font-family: "Courier New", Courier, monospace;
}

.info-text {
  width: 100%;
  text-align: left;
}

.checkbox-row {
  display: flex;
}
</style>