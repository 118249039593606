import { createApp } from 'vue'
import App from './App.vue'
import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";
import VueClipboard from 'vue3-clipboard'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
// import VueClipboard from 'vue-clipboard2'

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";


import router from './router'

const app = createApp(App);
app.use(VueSidebarMenuAkahon)
app.component('EasyDataTable', Vue3EasyDataTable);
app.use(router)
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
});
app.use(Toast, {});

app.mount('#app')


