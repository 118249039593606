<template>
  <div>
    <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />

    <div class="card-container">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-muted text-center mt-2 mb-4">
            <h4>Screen Size</h4>
            <span>{{ this.window.screenWidth }} x
              {{ this.window.screenHeight }}</span>
          </div>
        </div>
      </div>
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-muted text-center mt-2 mb-4">
            <h4>Window Size</h4>
            <span>{{ this.window.outerWidth }} x
              {{ this.window.outerHeight }}</span>
          </div>
        </div>
      </div>
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-muted text-center mt-2 mb-4">
            <h4>Pixel Ratio</h4>
            <span>{{ this.window.devicePixelRatio }} dppx </span>
          </div>
        </div>
      </div>
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-muted text-center mt-2 mb-4">
            <h4>Color Depth</h4>
            <span>{{ this.window.colorDepth }} </span>
          </div>
        </div>
      </div>
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-muted text-center mt-2 mb-4">
            <h4>Orientation Angle</h4>
            <span>{{ this.window.orientation }} </span>
          </div>
        </div>
      </div>
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-muted text-center mt-2 mb-4">
            <h4>Platform</h4>
            <span>{{ this.information.platform }} </span>
          </div>
        </div>
      </div>
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-muted text-center mt-2 mb-4">
            <h4>Browser Vendor</h4>
            <span>{{ this.information.vendor }} </span>
          </div>
        </div>
      </div>
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-muted text-center mt-2 mb-4">
            <h4>User Agent</h4>
            <span>{{ this.information.userAgent }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";


export default {
  name: "DeviceInfo",
  components: {
    ToolHeader
  },
  created() { },
  mounted() {
    document.title = "Tools - Device Info";
  },
  data() {
    return {
      curentComponent: tools.find((tool) => tool.id === "deviceInformation")
    };
  },
  computed: {
    information() {
      return {
        vendor: navigator.vendor,
        languages: navigator.languages.join(", "),
        platform: navigator.platform,
        userAgent: navigator.userAgent,
      };
    },
    window() {
      let win = window;

      return {
        outerHeight: win.outerHeight,
        innerHeight: win.innerHeight,
        outerWidth: win.outerWidth,
        innerWidth: win.innerWidth,
        screenWidth: win.screen.availWidth,
        screenHeight: win.screen.availHeight,
        devicePixelRatio: win.devicePixelRatio,
        colorDepth: win.screen.colorDepth,
        orientation: win.screen.orientation.angle,
      };
    },
  },
};
</script>

<style>
.card-container {
  margin-top: 10em;
  display: flex;
  row-gap: 10px;
  column-gap: 25px;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  justify-content: center;
}

.bg-secondary {
  background-color: #fafafa !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  background-color: #fff;
  background-clip: border-box;
}

.card:hover {
  transform: scale(1.1);
}

.card-body {
  padding: 1.5rem;
  flex: 1 1 auto;
}

.card-header {
  margin-bottom: 0;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fff;
}

.card-header:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}
</style>