<template>
  <div class="center">
    <h1>Page not found</h1>
    <img class="not-found-image" :src="mySVG" />
  </div>
</template>
<script>
export default {
  name: "NotFound",
  mounted() {
    document.title = "404";
  },
  data() {
    return {
      mySVG: require("@/assets/svg/404.svg"),
    };
  },
};
</script>
<style scoped>
.not-found-image {
  height: 90%;
  width: 90%;
}
</style>