<template>
  <div>
    <input class="file-upload" type="file" @change="addFile" />
  </div>
</template>

<script>
export default {
  name: "FileUpload",
  data() {
    return {
      file: [],
      uploaded: false,
      status: "",
      fileResult: "",
    };
  },
  computed: {
    uploadDisabled() {
      return this.files.length === 0;
    },
  },
  methods: {
    addFile(e) {
      var files = e.target.files || e.dataTransfer.files;
      console.log(files);
      if (!files.length) return;

      this.file = files[0];
      this.createInput(this.file);
    },
    removeFile(file) {
      this.files = this.files.filter((f) => {
        return f != file;
      });
    },
    createInput(file) {
      // eslint-disable-next-line
      let promise = new Promise((resolve, reject) => {
        var reader = new FileReader();
        var vm = this;
        // eslint-disable-next-line
        reader.onload = (e) => {
          resolve((vm.fileinput = reader.result));
        };
        reader.readAsText(file);
      });

      promise.then(
        (result) => {
          /* handle a successful result */
          this.fileResult = result;
          this.$emit("result", this.fileResult);
          console.log(result);
        },
        (error) => {
          /* handle an error */
          console.log(error);
        }
      );
    },
  },
};
</script>

<style scoped>
/* h1,
h2 {
  font-weight: normal;
}
a {
  color: #42b983;
}

.file-upload {
  background-color: #42b983 !important;
  display: block;
  position: fixed;
  z-index: 10001;
  left: 50%;
  top: 38.1966%;
  max-height: 90%;
  box-sizing: border-box;
  width: auto;
  transform: translate(-50%, -38.1966%);
  overflow: auto;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
} */
</style>
