<template>
  <div class="map-container">
    <div id="my-map" class="leaflet-map-container init"></div>
  </div>
</template>


<script>
import leaflet from "leaflet";

const INIT_COORDS = [56.15416876294262, 10.202202488730917];
const DEFAULT_OPTIONS = {
  theme: {
    name: "CartoDB.DarkMatterNoLabels",
    asset: "mapbox-monochrome-100x43.png",
    track_color: "#DAEC12",
  },
  lineOptions: {
    color: "#0CB1E8",
    weight: 1,
    opacity: 0.5,
    smoothFactor: 1,
    overrideExisting: true,
    detectColors: true,
  },
  markerOptions: {
    color: "#00FF00",
    weight: 3,
    radius: 5,
    opacity: 0.5,
  },
};

export default {
  name: "MAP",
  components: {},
  props: {
    initialCoordinates: {
      type: Array,
      required: false,
    },
    markers: {
      type: Array,
      required: false,
    },
  },
  created() {},
  mounted() {
    this.initMap();
    this.initLayers();
  },

  data() {
    return {
      options: DEFAULT_OPTIONS,
      current: 0,
      scrolled: null,
      map: null,
      tileLayer: null,
      layers: [],
      polylines: [],

      zoom: 13,
    };
  },
  methods: {
    initMap() {
      this.map = leaflet.map("my-map", {
        center:
          this.initialCoordinates != null
            ? this.initialCoordinates
            : INIT_COORDS,
        zoom: 13,
        preferCanvas: true,
        attributionControl: true,
        zoomControl: false,
      });
      leaflet
        .tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        })
        .addTo(this.map);

      this.addMarkers(this.markers);
      //   this.switchTheme(this.options.theme);
    },
    initLayers() {
      this.layers.forEach(() => {});
    },

    addMarkers(markers) {
      var defaultIcon = leaflet.icon({
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        iconUrl: "https://unpkg.com/leaflet@1.8.0/dist/images/marker-icon.png",
      });

      markers.forEach((marker) => {
        leaflet.marker(marker, { icon: defaultIcon }).addTo(this.map);
      });
    },
    switchTheme(theme) {
      if (this.mapTiles) {
        this.mapTiles.removeFrom(this.map);
      }
      if (theme.name.indexOf("mapbox") != -1) {
        leaflet
          .tileLayer(theme.name, {
            attribution:
              'Map data &copy; <a class="activity-link" href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
          })
          .addTo(this.map);
      }

      if (theme.name !== "No map" && theme.name.indexOf("mapbox") == -1) {
        this.mapTiles = leaflet.tileLayer.provider(theme.name);
        this.mapTiles.addTo(this.map, { detectRetina: true });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.leaflet-map-container {
  position: relative;
  min-height: 100%;
  overflow: hidden;
  width: 100%;
}

.init {
  min-height: 300px;
}

.map-container {
  position: relative;
  min-height: 100%;
  overflow: hidden;
  width: 100%;
}
</style>