import { shuffleString } from '@/utils/random';

export function createPassword(withUppercase, withLowercase, withNumbers, withSymbols, length) {
    const alphabet = [
        ...(withUppercase ? 'ABCDEFGHIJKLMOPQRSTUVWXYZ' : ''),
        ...(withLowercase ? 'abcdefghijklmopqrstuvwxyz' : ''),
        ...(withNumbers ? '0123456789' : ''),
        ...(withSymbols ? '.,;:!?./-"\'#{([-|\\@)]=}*+' : ''),
    ].join('');
    
    const pw = shuffleString(alphabet.repeat(length), '').substring(0, length)
    return pw;
}