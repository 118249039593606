<template>
    <div>
        <tool-header :title="curentComponent.name" :description="curentComponent.short_description" />
        <div>
            <div class="header-row">
                <div>CSV to convert</div>
                <file-upload @result="convertToJson"></file-upload>
                <easy-data-table v-if="this.headers != null" buttons-pagination alternating :headers="headers" :items="items" show-index  />
            </div>
        </div>
    </div>
</template>


<script>
import FileUpload from "@/components/FileUpload";

const csv2json = require("../utils/csv2json.js");
const json_beautifier = require("../utils/json_beautifier.js");
import ToolHeader from "@/components/ToolHeader";
import { tools } from "@/data/tools.js";

export default {
    name: "CsvTable",
    components: {
        FileUpload,
        EasyDataTable: window["vue3-easy-data-table"],
        ToolHeader


    },
    mounted() {
        document.title = "Tools - CSV Table";
    },
    created() { },
    data() {
        return {
            parseNumbers: true,
            minify: false,
            csv: ``,
            json: "",
            headers: null,
            items: null,
            curentComponent: tools.find((tool) => tool.id === "csvTable"),

        };
    },
    methods: {
        generateHeadersAndItems() {
            if (this.json.length > 0) {
                // Generate headers
                this.headers = Object.keys(this.json[0]).map(key => ({
                    text: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()), // Format the title
                    value: this.camelToSnakeCase(key),
                    // width: 200,
                    sortable: true
                }));

                // Transform items
                this.items = this.json.map(item => {
                    let newItem = {};
                    for (let key in item) {
                        // eslint-disable-next-line
                        if (item.hasOwnProperty(key)) {
                            newItem[this.camelToSnakeCase(key)] = item[key];
                        }
                    }
                    return newItem;
                });
            }
        },
        camelToSnakeCase(str) {
            return str.replace(/([A-Z])/g, "_$1").replace(/\s+/g, '_').toLowerCase();
        },
        convertToJson(result) {
            this.csv = result;
            let json = csv2json(result, { parseNumbers: true });
            let localJson = json_beautifier(json, {
                dropQuotesOnNumbers: true,
                inlineShortArrays: false,
                minify: false,
            });

            this.json = JSON.parse(localJson);
            

            console.log(this.json)
            this.generateHeadersAndItems();



        },
        showRow(item) {
            alert(JSON.stringify(item));
        }
    },
    computed: {

    },
};
</script>

<style scoped>
.header-row {
    min-height: 80px;
    padding: 10px;
}

.checkbox-row {
    display: flex;
}
</style>