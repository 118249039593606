<template>
    <div>
        <h1>{{ title }}</h1>
        <p><i>{{ description }}</i></p>
    </div>
</template>

<script>
export default {
    name: "ToolHeader",
    props: {
        title: {
            type: String,
        },
        description: {
            type: String,
        },
    },
};
</script>

<style scoped>
h1 {
    text-decoration: underline;
}
</style>