// Durstenfeld shuffle
function shuffleArrayMutate(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
}

function shuffleArray(array) {
    return shuffleArrayMutate([...array]);
}

function shuffleString(str, delimiter) {
    return shuffleArrayMutate(str.split(delimiter)).join(delimiter);
}

export { shuffleArray, shuffleArrayMutate, shuffleString };
